var $class="se2--promo-image-block",$name="PromoImageBlock",$path="app/components/PromoImageBlock/index.js",$this={$class,$name,$path,};import lazyHandleOpenModalButtons from 'app/components/utilities/lazyHandleOpenModalButtons';

import shell from 'app/modules/shell';

import 'app/partials/image';

export default shell.registerComponent($this, ({ addEventListener, mount }) => {
  mount((element) => {
    lazyHandleOpenModalButtons(addEventListener, element);
  });
});
